<script>
  import Fa from "svelte-fa";
  import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons";
  import {
    faFacebook,
    faTelegram,
    faWhatsapp,
    faInstagram,
    faTwitter,
    faTiktok,
    faYoutube,
    faLinkedin,
    faGithub
  } from "@fortawesome/free-brands-svg-icons";

  export const socials = [
    {
      icon: faGlobe,
      link: "https://murtajaziad.com",
    },
    {
      icon: faEnvelope,
      link: "mailto:mail@murtajaziad.com",
    },
    {
      icon: faInstagram,
      link: "https://instagram.com/murtajaziad",
    },
    {
      icon: faTwitter,
      link: "https://twitter.com/murtajaziad_",
    },
    {
      icon: faLinkedin,
      link: "https://linkedin.com/in/murtajaziad",
    },
    {
      icon: faFacebook,
      link: "https://www.facebook.com/murtajaziad",
    },
    {
      icon: faTelegram,
      link: "https://t.me/murtajaziad",
    },
    {
      icon: faWhatsapp,
      link: "https://wa.me/9647812666747",
    },

    {
      icon: faTiktok,
      link: "https://tiktok.com/@murtajaziad",
    },
    {
      icon: faYoutube,
      link: "https://youtube.com/c/MurtajaZiadOfficial",
    },
    {
      icon: faGithub,
      link: "https://github.com/murtajaziad",
    }
  ];
</script>

<main>
  <img src="/myimage.jpeg" alt="Murtaja Ziad" width="128" height="128" />
  <h1>Murtaja Ziad</h1>
  <p>A Full-Stack Web Developer from Baghdad, Iraq.</p>

  <div class="socials">
    {#each socials as social}
      <a href={social.link} target="_blank" rel="noreferrer noopener">
        <Fa icon={social.icon} fw />
      </a>
    {/each}
  </div>
</main>

<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;
  }

  img {
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    padding: 0;
    text-align: center;
  }

  p {
    margin: 0;
    opacity: 0.7;
    text-align: center;
  }

  .socials {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
  }

  .socials a {
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
  }

  .socials a:hover {
    color: #b08968;
    transform: scale(1.2);
  }
</style>
